import { forwardRef, useCallback, useRef, useState } from 'react';

import Avatar from 'components/Avatar';
import AvatarWithTitle from 'components/AvatarWithTitle';
import Input from 'components/Input';

import useConfig from 'hooks/useConfig';
import handleSubmitOnEnter from 'utilities/handleSubmitOnEnter';
import isFunction from 'utilities/isFunction';
import getUserReferences from 'utilities/getUserReferences';
import sendMentionedInCommentEmail from 'utilities/sendMentionedInCommentEmail';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import translations from './hooks/useTranslation';

import styles from './styles.module.scss';
import titleCase from 'utilities/titleCase';

const CommentInput = forwardRef((props, ref) => {
    const { ADD_COMMENT } = translations();
    const {
        className = '',
        commentType,
        commentTypeId,
        commentTypeName,
        commentUsersAndGroups=[],
        hasError = false,
        isRequired = false,
        onChange,
        onSubmit,
        onValid,
        placeholder = ADD_COMMENT,
        usedSchema,
        user = {},
        // value = ''
    } = props;

    const inputInnerRef = useRef()
    const [isOpen, setIsOpen] = useState(false);
    const [items, setItems] = useState([]);
    const [replaceableReference, setReplaceableReference] = useState();

    const { API_KEY } = useConfig();
    const { useSchema, useSchemaDescription } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [schemaDescription] = useSchemaDescription();

    function onInputInnerRef(ref){
        inputInnerRef.current = ref;
    }

    const handleChange = comment => {
        if(comment.includes('@')){
            const userReferenceIndices = [];
            for (let index = 0; index < comment.length; index++) {
                if (comment[index] === '@') {
                    userReferenceIndices.push(index);
                }
            }
            const userReference = userReferenceIndices[userReferenceIndices.length - 1];
            const referenceStart = comment.slice(userReference);
            const spaceCount = referenceStart.split(' ').length-1
            if(spaceCount < 2){
                const atReference = spaceCount >= 2 ? referenceStart.substring(0, referenceStart.indexOf(' ', referenceStart.indexOf(' ')+1)) : referenceStart;
                setReplaceableReference(atReference);
                const reference = atReference.replace('@', '');
                const allSelectable = commentUsersAndGroups.filter(item => (item.type === 'group' && item.schema === usedSchema) || (item.type === 'user' && item.schema === usedSchema));
                const users = allSelectable.filter(({query}) => query.toLowerCase().includes(reference.toLowerCase()));
                if (users.length > 0){
                    setIsOpen(true);
                    setItems(users);
                }else{
                    setIsOpen(false)
                }
            }else{
                setIsOpen(false)
            } 
        }else{
            setIsOpen(false);
        }
        
        onValid?.(comment);
        onChange?.(comment);
    };

    const handleSubmit = useCallback(
        event => {
            if (isFunction(onSubmit)) {
                const schemaForLink = usedSchema ? usedSchema : schema;
                const linkForEmail = commentType === 'marketing-request' ? `/${commentType}/${schemaForLink}/${commentTypeId}/:versionId`
                                        : `/${commentType}/${commentTypeId}/${schemaForLink}`
                const comment = event.target.value;
                
                const userReferences = getUserReferences(comment);
                const usedSchemaDescription = usedSchema ? titleCase(usedSchema) : schemaDescription;
                for( const user of userReferences){
                    sendMentionedInCommentEmail(comment, user?.email, linkForEmail, commentTypeName ? `${titleCase(commentType)}: ${commentTypeName}` : undefined, schema, usedSchemaDescription, `${localStorage.firstName} ${localStorage.lastName}`, API_KEY ? API_KEY : '')
                }
                onSubmit(event);
                inputInnerRef.current?.clear();
                setIsOpen(false);
            }
        },
        
        [API_KEY, onSubmit, commentType, commentTypeId, commentTypeName, schema, schemaDescription, usedSchema]
    );

    const handleUserClick = (user) => {
        const currentValue = document.getElementById('commentBox').value;
        const lastIndex = currentValue.lastIndexOf(replaceableReference);
        const value = [
            currentValue.substring(0, lastIndex).trim(),
            `@${user}`,
            currentValue.substring(lastIndex+replaceableReference.length).trim()
        ].filter(Boolean).join(' ');
        document.getElementById('commentBox').value = value;
        onValid?.(value);
        onChange?.(value);
    };

    return (
        <div className={`${className} ${styles.commentInput}`}>
            <Avatar className={styles.avatar} user={user} />

            <Input
                className={styles.input}
                hasError={hasError}
                id={'commentBox'}
                isRequired={isRequired}
                onChange={handleChange}
                onKeyUp={handleSubmitOnEnter(handleSubmit)}
                onRef={onInputInnerRef}
                placeholder={placeholder}
                ref={ref}
                type="text"
                // value={value}
            />

            {isOpen && 
            <div
                className={`${styles.drawer} ${styles['bottom']} ${
                    isOpen ? styles.isOpen : ''
                } ${className}`}
            >
                {items.map(
                    ({id, label }) => (
                        <button  className={styles.button} key={label} onClick={() => handleUserClick(label)}>
                            <AvatarWithTitle
                                className={styles.avatarWithTitle}
                                user={{id, name:label}}
                            />
                        </button>
                    )
                )}
            </div>}
        </div>
    );
});

export default CommentInput;
