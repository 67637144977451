import { memo, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';

import useConfig from 'hooks/useConfig';

import AddFormDetailsPanel from 'components/AddFormDetailsPanel';
import AddFormMetaPanel from 'components/AddFormMetaPanel';
import CancelSaveButtons from 'components/CancelSaveButtons';
import PanelWithAsidePanel from 'components/PanelWithAsidePanel';
import WithPageTitle from 'components/WithPageTitle';

import translations from './constants';

import styles from './styles.module.scss';

import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useFormComponents } from 'hooks/use-form-components.hook';

const AddForm = () => {
    const { useSchema, useTenants } = useGlobalStateHooks();
    const { ADD_NEW_FORM } = translations();

    const { EDIT_FORM_ROUTE, HOME } = useConfig();

    const [schema] = useSchema();
    const [tenants] = useTenants();

    const usedSchema = tenants.length === 1 ? tenants[0] : schema;

    const [createdForm, createForm, reset] = useData(usedSchema);

    const {deleteFormComponent, formComponents, registerFormComponent } = useFormComponents();

    const navigate = useNavigate();

    const [formElements, setFormElements] = useState([]);
    const [formTitle, setFormTitle] = useState();
    const [formNotes, setFormNotes] = useState();
    const [pendingTitleElement, setPendingTitleElement] = useState();
    const [pendingDescriptionElement, setPendingDescriptionElement] =
        useState();
    const [pendingTextInputElement, setPendingTextInputElement] = useState();
    const [pendingTextAreaInputElement, setPendingTextAreaInputElement] =
        useState();
    const [pendingRadioInputElement, setPendingRadioInputElement] = useState();
    const [
        pendingRadioInputElementOptions,
        setPendingRadioInputElementOptions
    ] = useState([{ label: '', name: '' }]);
    const [pendingDropDownInputElement, setPendingDropDownInputElement] =
        useState();
    const [
        pendingDropDownInputElementOptions,
        setPendingDropDownInputElementOptions
    ] = useState([{ label: '', value: '' }]);
    const [pendingCheckBoxInputElement, setPendingCheckBoxInputElement] =
        useState();
    const [
        pendingCheckBoxInputElementOptions,
        setPendingCheckBoxInputElementOptions
    ] = useState([{ label: '', value: '' }]);
    const [pendingConditionalElement, setPendingConditionalElement] =
        useState();
    const [resetElement, setResetElements] = useState(false);

    const onDragEnd = result => {
        const formComponent = result?.destination?.droppableId;
        const formIndex = result?.destination?.index;
        const sourceComponent = result?.source?.droppableId;
        const sourceIndex = result?.source?.index;
        const targetComponentId = result?.draggableId;

        const resortedElements = [...formElements];
        let newFormElements;

        if (formComponent !== 'formElements') {
            if (sourceComponent === 'formElements') {
                for (let i = 0; i < resortedElements.length; i++) {
                    if (
                        resortedElements[i]['index'] >= formIndex &&
                        resortedElements[i]['formComponent'] === formComponent
                    ) {
                        resortedElements[i]['index'] =
                            resortedElements[i]['index'] + 1;
                    }
                }
            } else {
                const modifiedElementIndex = resortedElements
                    .map(e => e.id)
                    .indexOf(result?.draggableId);
                const modifiedElement = resortedElements[modifiedElementIndex];
                resortedElements.splice(modifiedElementIndex, 1);
                for (let i = 0; i < resortedElements.length; i++) {
                    if (
                        resortedElements[i]['formComponent'] === formComponent
                    ) {
                        if (
                            resortedElements[i]['index'] > sourceIndex &&
                            resortedElements[i]['formIndex'] <= formIndex
                        ) {
                            resortedElements[i]['index'] =
                                resortedElements[i]['index'] - 1;
                        } else if (
                            resortedElements[i]['index'] < sourceIndex &&
                            resortedElements[i]['index'] >= formIndex
                        ) {
                            resortedElements[i]['index'] =
                                resortedElements[i]['index'] + 1;
                        }
                    }
                }
                resortedElements.push({
                    formComponent: modifiedElement?.formComponent,
                    id: modifiedElement?.id,
                    index: formIndex,
                    options: modifiedElement?.options,
                    text: modifiedElement?.text,
                    type: modifiedElement?.type
                });
            }

            if (result.draggableId === 'titleText') {
                const elementsWithAddedTitle = resortedElements;
                elementsWithAddedTitle.push({
                    formComponent: formComponent,
                    id: `titleText${uuid()}`,
                    index: formIndex,
                    options: [],
                    text: pendingTitleElement,
                    type: 'title'
                });
                newFormElements = elementsWithAddedTitle;
            } else if (result.draggableId === 'normalText') {
                const elementsWithAddedDescription = resortedElements;
                elementsWithAddedDescription.push({
                    formComponent: formComponent,
                    id: `normalText${uuid()}`,
                    index: formIndex,
                    options: [],
                    text: pendingDescriptionElement,
                    type: 'text'
                });
                newFormElements = elementsWithAddedDescription;
            } else if (result.draggableId === 'textInput') {
                const elementsWithAddedTextBox = resortedElements;
                elementsWithAddedTextBox.push({
                    formComponent: formComponent,
                    id: `textInput${uuid()}`,
                    index: formIndex,
                    options: [],
                    text: pendingTextInputElement,
                    type: 'textInput'
                });
                newFormElements = elementsWithAddedTextBox;
            } else if (result.draggableId === 'textAreaInput') {
                const elementsWithAddedTextAreaBox = resortedElements;
                elementsWithAddedTextAreaBox.push({
                    formComponent: formComponent,
                    id: `textAreaInput${uuid()}`,
                    index: formIndex,
                    options: [],
                    text: pendingTextAreaInputElement,
                    type: 'textAreaInput'
                });
                newFormElements = elementsWithAddedTextAreaBox;
            } else if (result.draggableId === 'radioInput') {
                const elementsWithAddedRadioButtons = resortedElements;
                elementsWithAddedRadioButtons.push({
                    formComponent: formComponent,
                    id: `radioInput${uuid()}`,
                    index: formIndex,
                    options: pendingRadioInputElementOptions,
                    text: pendingRadioInputElement,
                    type: 'radioInput'
                });
                newFormElements = elementsWithAddedRadioButtons;
            } else if (result.draggableId === 'dropDownInput') {
                const elementsWithAddedDropDown = resortedElements;
                elementsWithAddedDropDown.push({
                    formComponent: formComponent,
                    id: `dropDownInput${uuid()}`,
                    index: formIndex,
                    options: pendingDropDownInputElementOptions,
                    text: pendingDropDownInputElement,
                    type: 'dropDownInput'
                });
                newFormElements = elementsWithAddedDropDown;
            } else if (result.draggableId === 'checkBoxInput') {
                const elementsWithAddedCheckbox = resortedElements;
                elementsWithAddedCheckbox.push({
                    formComponent: formComponent,
                    id: `checkBoxInput${uuid()}`,
                    index: formIndex,
                    options: pendingCheckBoxInputElementOptions,
                    text: pendingCheckBoxInputElement,
                    type: 'checkBoxInput'
                });
                newFormElements = elementsWithAddedCheckbox;
            } else if (result.draggableId === 'conditional') {
                const elementsWithAddedConditional = resortedElements;
                const conditionalForms = resortedElements.filter(
                    x => x.id === pendingConditionalElement
                )[0];
                const conditionalOptions = conditionalForms?.options;
                const conditionalName = conditionalForms?.text;
                elementsWithAddedConditional.push({
                    formComponent: formComponent,
                    id: `conditional${uuid()}`,
                    index: formIndex,
                    options: conditionalOptions,
                    text: conditionalName,
                    type: 'conditional'
                });
                newFormElements = elementsWithAddedConditional;
            } else if (targetComponentId === 'table') {
                const elementsWithAddedTable = resortedElements;
                elementsWithAddedTable.push({
                    formComponent: formComponent,
                    id: `table-${uuid()}`,
                    index: formIndex,
                    text: 'Table',
                    type: 'table'
                });
                newFormElements = elementsWithAddedTable;
            } else {
                newFormElements = resortedElements;
            }
            setPendingTitleElement('');
            setPendingDescriptionElement('');
            setPendingTextInputElement('');
            setPendingTextAreaInputElement('');
            setPendingRadioInputElement('');
            setPendingRadioInputElementOptions([{ label: '', name: '' }]);
            setPendingDropDownInputElement('');
            setPendingDropDownInputElementOptions([{ label: '', value: '' }]);
            setPendingCheckBoxInputElement('');
            setPendingCheckBoxInputElementOptions([{ label: '', value: '' }]);
            setPendingConditionalElement('');
            setResetElements(true);
        }
        if (newFormElements) {
            setFormElements(newFormElements.sort((a, b) => a.index - b.index));
        }
    };

    const handleCancel = useCallback(() => {
        navigate(HOME);
    }, [HOME, navigate]);

    const handleSave = useCallback(() => {
        const elements = formElements.map(element => {
            const ref = formComponents[element.id];
            const settings = ref?.toExport();

            return {
                ...element,
                settings
            };
        });

        const formObject = {
            formElements: elements,
            formIsActive: true,
            formNotes,
            formTitle
        };
        createForm(formObject);
    }, [createForm, formTitle, formNotes, formElements]);

    useEffect(() => {
        if (!createdForm) {
            return;
        }

        navigate(
            EDIT_FORM_ROUTE.replace(':formId', createdForm).replace(
                ':formSchema',
                usedSchema
            )
        );

        reset();
    }, [EDIT_FORM_ROUTE, navigate, reset, createdForm]);

    return (
        <div className={styles.addActivity}>
            <WithPageTitle title={ADD_NEW_FORM}>
                <CancelSaveButtons
                    onCancel={handleCancel}
                    onSave={handleSave}
                />
            </WithPageTitle>

            <PanelWithAsidePanel>
                <DragDropContext onDragEnd={result => onDragEnd(result)}>
                    <AddFormDetailsPanel
                        formElements={formElements}
                        onDeleteFormComponent={deleteFormComponent}
                        onRegisterFormComponent={registerFormComponent}
                        setFormElements={setFormElements}
                        setFormNotes={setFormNotes}
                        setFormTitle={setFormTitle}
                    />

                    <AddFormMetaPanel
                        formElements={formElements}
                        pendingCheckBoxInputElementOptions={
                            pendingCheckBoxInputElementOptions
                        }
                        pendingDropDownInputElementOptions={
                            pendingDropDownInputElementOptions
                        }
                        pendingRadioInputElementOptions={
                            pendingRadioInputElementOptions
                        }
                        resetElement={resetElement}
                        setPendingCheckBoxInputElement={
                            setPendingCheckBoxInputElement
                        }
                        setPendingCheckBoxInputElementOptions={
                            setPendingCheckBoxInputElementOptions
                        }
                        setPendingConditionalElement={
                            setPendingConditionalElement
                        }
                        setPendingDescriptionElement={
                            setPendingDescriptionElement
                        }
                        setPendingDropDownInputElement={
                            setPendingDropDownInputElement
                        }
                        setPendingDropDownInputElementOptions={
                            setPendingDropDownInputElementOptions
                        }
                        setPendingRadioInputElement={
                            setPendingRadioInputElement
                        }
                        setPendingRadioInputElementOptions={
                            setPendingRadioInputElementOptions
                        }
                        setPendingTextAreaInputElement={
                            setPendingTextAreaInputElement
                        }
                        setPendingTextInputElement={setPendingTextInputElement}
                        setPendingTitleElement={setPendingTitleElement}
                        setResetElements={setResetElements}
                    />
                </DragDropContext>
            </PanelWithAsidePanel>
        </div>
    );
};

export default memo(AddForm);
