// import { useEffect } from 'react';

import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useMutation, gql } from '@apollo/client';

import state from 'app/state';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { activityDetailsPanel } = state;

const { activityDetailsPanelDropdownsAtom, activityDetailsDropdownChangingAtom } = activityDetailsPanel.atoms;
const { activityDetailsPanelDropdownsAtomFamily } =
    activityDetailsPanel.atomFamilies;

const MUTATE_DROPDOWN_DELETE = tenantSchema => gql`
    mutation updateDropdownSelectionDel($activityId:Int!, $dropdownIndex:String!) {
        update_${tenantSchema}_activity(
            where: {
                id: {_eq: $activityId}
            },
            _delete_key:{ dropdown_selections: $dropdownIndex }
        ) {
            returning {
                dropdown_selections
            }
        }
    }
`;

const MUTATE_DROPDOWN_INSERT = tenantSchema => gql`
    mutation updateDropdownSelectionIns($activityId:Int!, $dropdownObj:jsonb!) {
        update_${tenantSchema}_activity(
            where: {
                id: {_eq: $activityId}
            },
            _append:{ dropdown_selections: $dropdownObj}
        ) {
            returning {
                dropdown_selections
            }
        }
    }
`;

const MUTATE_ACTIVITY_HISTORY = tenantSchema => gql`
    mutation insert_activity_history_dropdown($activityId: Int!, $color: String = "yellow", $message: String = "changed a dropdown", $userId: Int!) {
        insert_${tenantSchema}_activity_history(
            objects: {
                activity_id: $activityId,
                color: $color,
                message: $message,
                user_id: $userId
            }
        ) {
            returning {
                id
            }
        }
    }  
`;

const useDropdowns = (schema, dropdowns = []) => {
    const { useUserId } = useGlobalStateHooks();

    const [userId] = useUserId();

    const dropdownIds = useRecoilValue(activityDetailsPanelDropdownsAtom);

    const [removeDropdown] = useMutation(MUTATE_DROPDOWN_DELETE(schema));
    const [createDropdown] = useMutation(MUTATE_DROPDOWN_INSERT(schema));
    const [createHistory] = useMutation(MUTATE_ACTIVITY_HISTORY(schema));


    const insertDropdown = useRecoilCallback(
        ({ reset, set }) =>
            ({
                activityId,
                changedDropdownIndex,
                id,
                options,
                selectedIndex,
                title
            }) => {
                set(activityDetailsDropdownChangingAtom, true);

                const deleteDropdown = async () => {
                    const indexOfId = dropdownIds.indexOf(id);
                    const mutatedDropdownIds = [...dropdownIds];

                    mutatedDropdownIds.splice(indexOfId, 1);

                    set(activityDetailsPanelDropdownsAtom, mutatedDropdownIds);

                    reset(activityDetailsPanelDropdownsAtomFamily(id));

                    const asyncDeleteDropdown = async () => {
                        await removeDropdown({
                            variables: {
                                activityId,
                                dropdownIndex: id
                            }
                        });
                    };

                    await asyncDeleteDropdown();
                }

                const setDropdown = async () => {
                    set(activityDetailsPanelDropdownsAtomFamily(id), {
                        changedDropdownIndex,
                        id,
                        options,
                        selectedIndex,
                        title
                    });

                    const dropdownObj = {
                        [id]: selectedIndex
                    };

                    const asyncUpdateDropdown = async () => {
                        await createDropdown({
                            variables: {
                                activityId,
                                dropdownObj
                            }
                        });
                    };

                    await asyncUpdateDropdown();
                }

                const upsertDropdown = async () => {
                    await deleteDropdown();
                    await setDropdown();
                }

                upsertDropdown()
                set(activityDetailsDropdownChangingAtom, false);
            }
    );



    const insertHistory = useRecoilCallback(() => ({ activityId }) => {
        const asyncUpdateActivityHistory = async () => {
            await createHistory({
                variables: {
                    activityId,
                    userId
                }
            });
        };

        asyncUpdateActivityHistory();
    });
    
    return [
        dropdownIds,

        ({
            activityId,
            _changedDropdownId,
            changedDropdownIndex,
            _dropdownSelection,
            _formData,
            selectedIndex
        }) => {
            const { id, options, title } = dropdowns[changedDropdownIndex];

            insertDropdown({
                activityId,
                changedDropdownIndex,
                id,
                options,
                selectedIndex,
                title
            });

            insertHistory({ activityId });
        }
    ];
};

export default useDropdowns;
