import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Activities from 'pages/Activities';
import ActivitiesReport from 'pages/ActivitiesReport';
import Activity from 'pages/Activity';
import ActivityTypeEditForm from 'pages/ActivityTypeEditForm';
import AddActivity from 'pages/AddActivity';
import AddActivityType from 'pages/AddActivityType';
import AddForm from 'pages/AddForm';
import AddLogEntry from 'pages/AddLogEntry';
import AddGroup from 'pages/AddGroup';
import AddMarketingRequest from 'pages/AddMarketingRequest';
import AddPerson from 'pages/AddPerson';
import AddPolicy from 'pages/AddPolicy';
import Administration from 'pages/Administration';
import BuildPolicy from 'pages/BuildPolicy';
import Case from 'pages/Case';
import CaseReports from 'pages/CaseReports';
import CaseManagement from 'pages/CaseManagement';
import ComplianceLog from 'pages/ComplianceLog';
import ComplianceLogEntry from 'pages/ComplianceLogEntry';
import DocumentManagement from 'pages/DocumentManagement';
import EditActivity from 'pages/EditActivity';
import EditGroup from 'pages/EditGroup';
import EditPerson from 'pages/EditPerson';
import Form from 'pages/Form';
import FormsManagement from 'pages/FormsManagement';
import Group from 'pages/Group';
import Home from 'pages/Home';
import HumanResources from 'pages/HumanResources';
import InternalServerError from 'pages/InternalServerError';
import LogReports from 'pages/LogReports';
import MarketingRequest from 'pages/MarketingRequest';
import MarketingRequests from 'pages/MarketingRequests';
import NotFoundError from 'pages/NotFoundError';
import Policy from 'pages/Policy';
import PolicyReports from 'pages/PolicyReports';
import ProgramOverview from 'pages/ProgramOverview';
import Reports from 'pages/Reports';
import SkematicReport from 'pages/SkematicReport';
import Spinner from 'components/Spinner';

import RouteInterceptor from 'components/RouteInterceptor';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useConfig from 'hooks/useConfig';

import styles from './styles.module.scss';

const loading = <Spinner />;

const Content = ({ className = '' }) => {
    const {
        ACTIVITIES_ROUTE,
        ACTIVITIES_REPORTS,
        ACTIVITY_ROUTE,
        ACTIVITY_TYPE_ROUTE,
        ADD_ACTIVITY_ROUTE,
        ADD_ACTIVITY_TYPE,
        ADD_FORM_ROUTE,
        ADD_LOG_ENTRY_ROUTE,
        ADD_GROUP,
        ADD_MARKETING_REQUEST_ROUTE,
        ADD_PERSON,
        ADD_POLICY_ROUTE,
        ADMIN,
        BUILD_POLICY_ROUTE,
        CASE_MANAGEMENT_ROUTE,
        CASE_ROUTE,
        CASES_REPORTS,
        COMPLIANCE_LOG_ROUTE,
        COMPLIANCE_LOG_ENTRY_ROUTE,
        DOCUMENT_MANAGEMENT_ROUTE,
        EDIT_ACTIVITY_ROUTE,
        EDIT_GROUP,
        EDIT_PERSON_ROUTE,
        EDIT_FORM_ROUTE,
        FORMS_MANAGEMENT_ROUTE,
        GROUP_ROUTE,
        HOME,
        HUMAN_RESOURCES,
        INTERNAL_SERVER_ERROR,
        LOGS_REPORTS,
        MARKETING_REQUEST_ROUTE,
        MARKETING_REQUESTS_ROUTE,
        POLICY_REPORTS,
        POLICY_ROUTE,
        PROGRAM_OVERVIEW,
        REPORTS,
        SKEMATIC_REPORT
    } = useConfig();

    const { useConsultancyUsers } = useGlobalStateHooks();
    const [consultancyUsers] = useConsultancyUsers();

    return (
        <main className={`${className} ${styles.content}`}>
            <Suspense fallback={loading}>
                <RouteInterceptor consultancyUsers={consultancyUsers}>
                    <Routes>
                        <Route element={<Activities />} path={ACTIVITIES_ROUTE} />

                        <Route
                            element={<AddActivity />}
                            path={ADD_ACTIVITY_ROUTE}
                        />

                        <Route element={<Activity />} path={ACTIVITY_ROUTE} />

                        <Route
                            element={<ActivityTypeEditForm />}
                            path={ACTIVITY_TYPE_ROUTE}
                        />

                        <Route
                            element={<AddLogEntry />}
                            path={ADD_LOG_ENTRY_ROUTE}
                        />

                        <Route
                            element={<AddMarketingRequest />}
                            path={ADD_MARKETING_REQUEST_ROUTE}
                        />

                        <Route
                            element={<AddActivityType />}
                            path={ADD_ACTIVITY_TYPE}
                        />

                        <Route element={<AddPerson />} path={ADD_PERSON} />

                        <Route element={<AddPolicy />} path={ADD_POLICY_ROUTE} />

                        <Route element={<Administration />} path={ADMIN} />

                        <Route element={<AddGroup />} path={ADD_GROUP} />

                        <Route element={<BuildPolicy />} path={BUILD_POLICY_ROUTE} />

                        <Route element={<EditGroup />} path={EDIT_GROUP} />

                        <Route element={<Case />} path={CASE_ROUTE} />

                        <Route
                            element={<CaseManagement />}
                            path={CASE_MANAGEMENT_ROUTE}
                        />

                        <Route
                            element={<ComplianceLog />}
                            path={COMPLIANCE_LOG_ROUTE}
                        />

                        <Route
                            element={<ComplianceLogEntry />}
                            path={COMPLIANCE_LOG_ENTRY_ROUTE}
                        />

                        <Route
                            element={<DocumentManagement />}
                            path={DOCUMENT_MANAGEMENT_ROUTE}
                        />

                        <Route
                            element={<EditActivity />}
                            path={EDIT_ACTIVITY_ROUTE}
                        />

                        <Route element={<EditPerson />} path={EDIT_PERSON_ROUTE} />

                        <Route
                            element={<FormsManagement />}
                            path={FORMS_MANAGEMENT_ROUTE}
                        />

                        <Route
                            element={<Group />}
                            path={GROUP_ROUTE}
                        />

                        <Route element={<Home />} path={HOME} />

                        <Route
                            element={<HumanResources />}
                            path={HUMAN_RESOURCES}
                        />

                        <Route
                            element={<InternalServerError />}
                            path={INTERNAL_SERVER_ERROR}
                        />

                        <Route
                            element={<MarketingRequest />}
                            path={MARKETING_REQUEST_ROUTE}
                        />

                        <Route
                            element={<MarketingRequests />}
                            path={MARKETING_REQUESTS_ROUTE}
                        />

                        <Route element={<Policy />} path={POLICY_ROUTE} />

                        <Route
                            element={<ProgramOverview />}
                            path={PROGRAM_OVERVIEW}
                        />

                        <Route element={<Reports />} path={REPORTS} />

                        <Route element={<ActivitiesReport />} path={ACTIVITIES_REPORTS} />

                        <Route element={<CaseReports />} path={CASES_REPORTS} />

                        <Route element={<LogReports />} path={LOGS_REPORTS} />

                        <Route element={<PolicyReports />} path={POLICY_REPORTS} />

                        <Route element={<SkematicReport />} path={SKEMATIC_REPORT} />

                        <Route
                            element={<AddForm />}
                            path={ADD_FORM_ROUTE}
                        />

                        <Route
                            element={<Form />}
                            path={EDIT_FORM_ROUTE}
                        />

                        <Route element={<NotFoundError />} path="*" />
                    </Routes>
                </RouteInterceptor>
            </Suspense>
        </main>
    );
};

export default Content;
