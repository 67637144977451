import { useCallback, useState } from 'react';

import ActiveIndicator from 'components/ActiveIndicator';
import DetailsButton from 'components/DetailsButton';
import PenIcon from 'components/icons/PenIcon';
import TableRow from 'components/TableRow';

import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';
import EditSectionModal from 'components/EditSectionModal';
import titleCase from 'utilities/titleCase';
import { Badges } from 'components/badges/badges';

const DocumentManagementSectionsTableRow = props => {
    const { assignedDocuments, description, documentCategories, effectiveDate, id, name, published, riskLabel, schema, selectedCategories, status, tenantCount, version } = props;

    const [isOpen, setIsOpen] = useState(false);

    const { EDIT } = useTranslation();
    
    const handleClick = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const isActive = status.toLowerCase() === 'active';
    const isPublished = published.toLowerCase() === 'published';

    return (
        <TableRow className={tenantCount>1 ? styles.documentManagementTableRowMulti : styles.documentManagementTableRow}>
            <div
                className={styles.link}
                onClick={handleClick}
            >{name}</div>

            {/* <BodyText className={styles.version} text={version || '-'} />

            <BodyText text={effectiveDate} />

            <RiskPill riskLevel={riskLabel} /> */}

            {tenantCount>1 && <div>{titleCase(schema)}</div>}

            <Badges items={selectedCategories} />

            <Badges items={assignedDocuments.map(item => ({...item, label: item.name}))} />

            <Badges items={documentCategories} />

            <ActiveIndicator
                className={styles.statusIcon}
                isActive={isActive}
            />

            <ActiveIndicator
                className={styles.statusIcon}
                isActive={isPublished}
            />

            <DetailsButton
                className={styles.detailsButton}
                items={[
                    {
                        icon: <PenIcon />,

                        onClick: () => {
                        },

                        text: EDIT
                    }
                ]}
            />

            {isOpen && <EditSectionModal
                assignedDocuments={assignedDocuments}
                description={description}
                effectiveDate={effectiveDate}
                id={id}
                name={name}
                onClose={handleClose}
                published={published}
                riskLabel={riskLabel}
                selectedCategories={selectedCategories}
                status={status}
                version={version}
            />}
        </TableRow>
    );
};

export default DocumentManagementSectionsTableRow;
