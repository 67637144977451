import { useEffect, useState } from 'react';

import Activities from 'components/Activities';
import CompletedSummarizedItems from 'components/CompletedSummarizedItems';
import InProgressSummarizedItems from 'components/InProgressSummarizedItems';
import OverdueSummarizedItems from 'components/OverdueSummarizedItems';
import UpcomingSummarizedItems from 'components/UpcomingSummarizedItems';
import PanelLoader from 'components/PanelLoader';

// import useActivities from './hooks/useActivities';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { useExportActivities } from 'hooks/useExportActivities';
import { useGetActivitiesAtGlance } from 'queries/activities-at-glance/activities-at-glance.query';

const SummarizedActivities = ({ isAll, isFlagged }) => {
    const { useActivities, useUserId, useTenants } = useGlobalStateHooks();
    const [activities] = useActivities();
    const [userId] = useUserId();
    const [tenants] = useTenants();
    const { exportActivities } = useExportActivities();

    const [hasCompletedItems, setHasCompletedItems] = useState();
    const [hasOverdueItems, setHasOverdueItems] = useState();
    const [hasInProgressItems, setHasInProgressItems] = useState();
    const [hasUpcomingItems, setHasUpcomingItems] = useState();

    useEffect(() => {
        const { completed, inProgress, overdue, upcoming } = activities;
        setHasCompletedItems(completed?.length > 0);
        setHasInProgressItems(inProgress?.length > 0);
        setHasUpcomingItems(upcoming?.length > 0);
        setHasOverdueItems(overdue?.length > 0);
    }, [
        activities,
        setHasCompletedItems,
        setHasInProgressItems,
        setHasUpcomingItems,
        setHasOverdueItems
    ]);

    const activitiesAtGlance = useGetActivitiesAtGlance({
        isAssignedToMe: !isAll,
        isFlagged: false,
        schemas: tenants,
        userId: Number(userId)
    });

    async function onDownloadItem({
        complete = [],
        inprogress = [],
        overdue = [],
        upcoming = []
    }) {
        exportActivities({
            complete,
            inprogress,
            name: 'Activity',
            overdue,
            upcoming
        });
    }

    return (
        <Activities
            hasCompleteItems={hasCompletedItems}
            hasInProgressItems={hasInProgressItems}
            hasOverdueItems={hasOverdueItems}
            hasUpcomingItems={hasUpcomingItems}
            isAll={isAll}
            isFlagged={isFlagged}
        >
            {hasOverdueItems && (
                <OverdueSummarizedItems
                    items={activitiesAtGlance?.result?.overdue}
                    onDownloadItem={item => onDownloadItem({ overdue: [item] })}
                />
            )}

            {hasInProgressItems && (
                <InProgressSummarizedItems
                    items={activitiesAtGlance?.result?.pending}
                    onDownloadItem={item =>
                        onDownloadItem({ inprogress: [item] })
                    }
                />
            )}

            {hasUpcomingItems && (
                <UpcomingSummarizedItems
                    items={activitiesAtGlance?.result?.upcoming}
                    onDownloadItem={item =>
                        onDownloadItem({ upcoming: [item] })
                    }
                />
            )}

            {hasCompletedItems && (
                <CompletedSummarizedItems
                    items={activitiesAtGlance?.result?.completed}
                    onDownloadItem={item =>
                        onDownloadItem({ complete: [item] })
                    }
                />
            )}

            {!(hasOverdueItems || hasInProgressItems) &&
                (hasUpcomingItems || hasCompletedItems) && (
                    <PanelLoader
                        text={'There are no activities for your completion'}
                    />
                )}
        </Activities>
    );
};

export default SummarizedActivities;
