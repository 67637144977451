import { useRef } from 'react';

export function useFormComponents() {
    const componentsRef = useRef({});

    function deleteFormComponent(id) {
        delete componentsRef.current[id];
    }

    function getFormComponent(id) {
        return componentsRef.current[id];
    }

    function registerFormComponent(id) {
        return ref => {
            if (ref) {
                componentsRef.current[id] = ref;
            }
        };
    }

    return {
        deleteFormComponent,
        formComponents: componentsRef.current,
        getFormComponent,
        registerFormComponent
    };
}
