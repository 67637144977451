import { useCallback, useEffect, useState } from 'react';
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import MetaPanel from 'components/MetaPanel';
import ContentPanel from 'components/ContentPanel';
import PanelTitle from 'components/PageTitle';
import PanelBody from 'components/PanelBody';
import TextInput from 'components/TextInput';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import MoveIcon from 'components/icons/MoveIcon';
import EditFormDetailsConditionalElement from 'components/EditFormDetailsConditionalElement';
import TextareaInput from 'components/TextareaInput';
import Dropdown from 'components/Dropdown';
import DynamicRadioButtons from 'components/DynamicRadioButtons';
import Label from 'components/Label';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';
import useCreateForm from './hooks/useCreateForm';
import FormComponent from 'components/FormComponent';

import Buttonize from 'components/Buttonize';
import CloseIcon from 'components/icons/CloseIcon';
import Button from 'components/Button';

const FormMetaPanel = props => {
    const {
        className = '',
        form = {},
        formElements,
        isEditing,
        loading,
        setFormElements,
        setIsEditing,
        usedSchema
    } = props;
    const { FORM_PREVIEW } = useTranslation();

    const { EDIT_FORM_ROUTE } = useConfig();

    const navigate = useNavigate();

    const { questions } = form;

    const [selections, setSelections] = useState({});

    const handleCancel = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);

    const handleEdit = useCallback(() => {
        setIsEditing(true);
    }, [setIsEditing]);

    const handleRemoveElement = (_, element) => {
        const elementIndex = formElements.findIndex(elements => elements?.questionId === element?.questionId);
        const deletedElement = formElements[elementIndex];
        const formComponent = deletedElement?.formComponent;
        const formIndex = deletedElement?.index;
        const formElementsToModify = [...formElements];
        formElementsToModify.splice(elementIndex, 1);
        const changedElements = [...formElementsToModify];

        const newFormElements = [];
        for (let i = 0; i < changedElements.length; i++) {
            const newElement = {...changedElements[i]};
            if(newElement['formComponent'] === formComponent){
                if(newElement['index'] > formIndex){
                    newElement['index'] = newElement['index'] - 1;
                }
            }
            newFormElements.push(newElement);
        }
        setFormElements(newFormElements);
    };

    const [createdForm, createForm, reset] = useCreateForm();

    function handleSave() {
        const formObject = {
            formElements,
            formIsActive: true,
            formNotes:form?.notes,
            formParentId:form?.id,
            formRootId:form?.rootFormId,
            formTitle:form?.name
        }
        
        createForm(formObject);
        // eslint-disable-next-line
    };

    useEffect(() => {
        if (!createdForm) {
            return;
        }

        navigate(EDIT_FORM_ROUTE.replace(':formId', createdForm).replace(':formSchema', usedSchema));

        reset();
    }, [EDIT_FORM_ROUTE, navigate, reset, createdForm, usedSchema]);    

    const handleUpdateSelections = useCallback((questionName, selected) => {
        const modifiedSelections = {...selections};
        modifiedSelections[questionName] = selected;
        setSelections(modifiedSelections);
    }, [selections, setSelections]);

    useEffect(() => {
    }, [selections])

    return (
        <MetaPanel className={className}>
            <PanelBody className={styles.panelBody} loading={loading}>
                <div className={styles.titleLine}>
                    <PanelTitle className={styles.formPreview} text={FORM_PREVIEW} />

                    {!isEditing && (
                        <Button
                            className={`${className} ${styles.editButton}`}
                            onClick={handleEdit}
                        >
                            Edit Form
                        </Button>
                    )}

                    {isEditing && (
                        <span className={styles.buttonList}>
                            {/* <Button
                                className={`${className} ${styles.saveButton}`}
                                onClick={handleCancel}
                            >
                                Save New Version
                            </Button> */}

                            <Button
                                className={`${className} ${styles.saveButton}`}
                                id={'saveNewForm'}
                                onClick={handleSave}
                            >
                                Save
                            </Button>

                            <Button
                                className={`${className} ${styles.cancelButton}`}
                                id={'cancelNewForm'}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </span>
                    )}
                </div>
                
                {!isEditing && questions && questions.map(({ question, /*required*/ }) => {
                    if(question?.formComponent === "newForm"){
                        return(
                            <FormComponent
                                handleUpdateSelections={handleUpdateSelections}
                                question={question}
                                questions={questions}
                                selections={selections}
                            />
                        );
                    }else{
                        return(<></>);
                    }
                })}

                {isEditing && (
                    <ContentPanel
                        className={`${className} ${styles.addFormDetailsPanel}`}
                    >
                        <PanelBody className={styles.panelBody}>
                            <Droppable className={styles.panelBody} droppableId={'newForm'} key={'newForm'}>
                                {(provided, snapshot) => {
                                    return (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{
                                            background: snapshot.isDraggingOver
                                                ? "#f9f9f9"
                                                : "inherit",
                                            minHeight: 800,
                                            padding: 4,
                                            width: '100%'
                                            }}
                                        >
                                            {formElements && formElements.map((element, index) => {
                                                return(
                                                <Draggable
                                                    draggableId={element.questionId}
                                                    index={element.index}
                                                    key={element.questionId}
                                                >
                                                    {(provided, _snapshot) => {
                                                        if(element?.formComponent === 'newForm'){
                                                            return (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    style={{
                                                                        padding: 16,
                                                                        userSelect: "none",
                                                                        ...provided.draggableProps.style
                                                                    }}
                                                                >
                                                                    {element.type === 'title' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <h2>{element.name}</h2>

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {element.type === 'text' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <p>{element.name}</p>

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {element.type === 'textInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <TextInput
                                                                                label={element.name}
                                                                            />

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {element.type === 'textAreaInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <TextareaInput
                                                                                label={element.name}
                                                                            />

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {element.type === 'radioInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <div>
                                                                                <Label text={element.name} />

                                                                                <DynamicRadioButtons
                                                                                    className={styles.radioButtons} 
                                                                                    items={element.choices}
                                                                                />
                                                                            </div>

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {element.type === 'dropDownInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <Dropdown items={element.choices} label={element.name} />

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {element.type === 'checkBoxInput' &&
                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <div>
                                                                                <Label text={element.name} />

                                                                                {element.choices.map((option, _index) => {
                                                                                    return(
                                                                                        <div>
                                                                                            <CheckboxWithLabel text={option.label} />
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                    
                                                                    {element.type === 'conditional' &&

                                                                        <div className={`${styles.elementContainer} ${styles.draggable}`}>
                                                                            <span
                                                                                className={styles.icon}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <MoveIcon className={styles.iconComponent}/>
                                                                            </span>

                                                                            <h4>Conditional on field '{element.name}'</h4>

                                                                            <div></div>

                                                                            <EditFormDetailsConditionalElement element={element} formElements={formElements} index={index} setFormElements={setFormElements} />

                                                                            <Buttonize
                                                                                className={styles.icon}
                                                                                onClick={res => handleRemoveElement(res, element)}
                                                                            >
                                                                                <CloseIcon className={styles.closeIconComponent}/>
                                                                            </Buttonize>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }else{
                                                            return(
                                                                <div
                                                                    ref={provided.innerRef}
                                                                >
                                                                </div>
                                                            )
                                                        }
                                                    }}
                                                </Draggable>
                                            )})}
                                            
                                        {provided.placeholder}
                                        </div>
                                    )
                                }}
                            </Droppable>
                        </PanelBody>
                    </ContentPanel>
                )}

            </PanelBody>
        </MetaPanel>
    );
};

export default FormMetaPanel;
