import { useEffect, useState } from 'react';
import { /*useNavigate,*/ useParams } from 'react-router-dom';

import FormDetailsTab from 'components/FormDetailsTab';
// import FormHistoryTab from 'components/FormHistoryTab';
// import FormMetaTab from 'components/FormMetaTab';
import WithPageTitle from 'components/WithPageTitle';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import useData from './hooks/useData';

import styles from './styles.module.scss';

import normalizeData from './data';

const Form = () => {
    const params = useParams();

    const { formId, formSchema } = params;

    const { useQuerySchemas, useSchema, useUserId } = useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas();
    const [schema] = useSchema();
    const [userId] = useUserId();

    const [form, setForm] = useState();

    const { _loading, _error, data } = useData(formId, formSchema);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema: formSchema ? formSchema : schema,
                querySchemas,
                user: userId
            });
            setForm(normalizedData);
        }
    }, [data, setForm]);

    // if (error) {
    //     navigate(INTERNAL_SERVER_ERROR);

    //     return;
    // }

    if (form){
        return (
            <div className={styles.case}>
                <WithPageTitle title={form?.name}>
                    {/* <CaseActions caseId={cases.id} caseRecord={cases} /> */}
                </WithPageTitle>

                <FormDetailsTab form={form} />
            </div>
        );
    }else{
        return
    }
};

export default Form;
