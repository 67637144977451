import { atom } from 'recoil';

const updatePersonAtom = atom({
    default: '',
    key: 'updatePerson'
});

const state = {
    atoms: {
        updatePersonAtom
    }
};

export default state;
