import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { addForm } = state;

const { addFormAtom } = addForm.atoms;

const MUTATE_FORM = tenantSchema => gql`
    mutation insertForm (
        $createdBy: Int!,
        $formTitle: String!, 
        $formIsActive: Boolean!, 
        $formNotes: String!,
        $formParentId: Int,
        $formRootId: Int
    ) {
        insert_${tenantSchema}_form(
            objects: {
                created_by: $createdBy,
                name: $formTitle,
                notes: $formNotes,
                is_active: $formIsActive,
                parent_form_id: $formParentId,
                root_form_id: $formRootId
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_QUESTION = tenantSchema => gql`
    mutation insertQuestions(
        $formComponent: String!,
        $index: Int!,
        $questionIdString: String!,
        $name: String, 
        $type: String!
    ) {
        insert_${tenantSchema}_question(objects: {
            formComponent: $formComponent,
            index: $index,
            name: $name, 
            questionId: $questionIdString,
            type: $type
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_CHOICE = tenantSchema => gql`
    mutation insertChoice(
        $questionId: Int!, 
        $text: String!
    ) {
        insert_${tenantSchema}_choice(objects: {
            question_id: $questionId, 
            text: $text
        }) {
            returning {
                id
            }
        }
    }
`;

const MUTATE_FORM_QUESTIONS = tenantSchema => gql`
    mutation inserFormQuestions(
        $formId: Int!, 
        $questionId: Int!,
        $required: Boolean = false
    ) {
        insert_${tenantSchema}_form_questions(objects: {
            form_id: $formId,
            question_id: $questionId,
            required: $required
        }) {
            returning {
                id
            }
        }
    }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE_FORM, providedSchema);
    const mutationQuestion = useGraphQL(MUTATE_QUESTION, providedSchema);
    const mutationChoice = useGraphQL(MUTATE_CHOICE, providedSchema);
    const mutationFormQuestions = useGraphQL(MUTATE_FORM_QUESTIONS, providedSchema);

    const { useSchema, useUserId } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [userId] = useUserId();

    const usedSchema = providedSchema ? providedSchema : schema;

    const createForm = useRecoilCallback(({ set }) => formValues => {
        formValues.createdBy = userId;

        const runMutation = async () => {
            const res = await mutation(formValues);
            const id = res?.data[`insert_${usedSchema}_form`].returning[0]?.id;
            const questionIds = []

            for(const { formComponent, index, choices, id:questionIdString, name, type } of formValues?.formElements){
                const usedName = name ? name : "";
                const questionRes = await mutationQuestion({formComponent, index, questionIdString, name:usedName, type});
                const questionId = questionRes?.data[`insert_${usedSchema}_question`].returning[0]?.id;
                questionIds.push(questionId);
                if (choices.length > 0){
                    for (let option of choices) {
                        mutationChoice({questionId, text:option?.label})
                    }
                }
            }
            
            for (let questionId of questionIds) {
                await mutationFormQuestions({formId: id, questionId})
            }

            set(addFormAtom, id);
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(addFormAtom, '');
    });

    return [useRecoilValue(addFormAtom), createForm, reset];
};

export default useData;
