import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const { updateActivityName } = state;

const { updateActivityNameAtom } = updateActivityName.atoms;

const MUTATE = tenantSchema => gql`
mutation mutateFormName($text: String!, $formId: Int!) {
    update_${tenantSchema}_form(where: {id: {_eq: $formId}}, _set: {name: $text}) {
      returning {
        name
      }
    }
  }
`;

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATE, providedSchema);

    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const usedSchema = providedSchema ? providedSchema : schema;

    const saveFormName = useRecoilCallback(({ set }) => variables => {
        const runMutation = async () => {
            if(variables?.text !== ''){
              const res = await mutation(variables);

              const newName = res?.data[`update_${usedSchema}_form`].returning[0]?.name;

              set(updateActivityNameAtom, newName);
            }
        };

        runMutation();
    });

    const reset = useRecoilCallback(({ set }) => () => {
        set(updateActivityNameAtom, '');
    });

    return [useRecoilValue(updateActivityNameAtom), saveFormName, reset];
};

export default useData;
