const resolveQuestions = questions =>
    questions.reduce((accumulator, formQuestion) => {
        const { required, question } = formQuestion;

        const { formComponent, index, name, questionId, type, id, choices, settings} = question;
        
        const modifiedChoices = choices.reduce((accumulator, choice) => {
            accumulator.push({
                id: choice?.id,
                label: choice?.text,
                value: choice?.text
            })
            return accumulator;
        }, [])
        
        const modifiedQuestion = {
            choices: modifiedChoices,
            formComponent,
            id,
            index,
            name,
            questionId,
            settings,
            type
        }

        accumulator.push({
            question: modifiedQuestion,
            required
        })

        return accumulator;
    }, []);

const resoveActivities = formActivityForms =>
formActivityForms.reduce((accumulator, activityForm) => {
        const { activity, assignment, assignment_type:assignmentType } = activityForm;

        accumulator.push({
            activityId: activity?.id,
            activityName: activity?.name,
            assignment,
            assignmentType
        });

        return accumulator;
    }, []);

const normalizeData = ({ data, schema, querySchemas }) => {
    const form = data[`${schema}_form_by_pk`];
    const {
        activity_forms: formActivityForms,
        form_questions: formQuestions,
        id,
        is_active: isActive,
        name,
        notes,
        parent_form: parentForm,
        parent_form_id: parentFormId,
        root_form: rootForm,
        root_form_id: rootFormId,
        tenant_user: formCreatedBy
    } = form;

    const activityForms = resoveActivities(formActivityForms)
    const questions = resolveQuestions(formQuestions)
    var createdBy
    if(querySchemas){
        for(const s of querySchemas){
            const user = formCreatedBy[`user_${s.value}`];
            if(user){
                createdBy = {
                    firstName: user?.first_name,
                    id: user?.id,
                    lastName: user?.last_name
                }
            }
        }
    }else{
        const user = formCreatedBy[`user_${schema}`];
        createdBy = {
            firstName: user?.first_name,
            id: formCreatedBy?.id,
            lastName: user?.last_name
        }
    }

    const modifiedForm = {
        activityForms,
        createdBy,
        id,
        isActive,
        name,
        notes,
        parentForm:parentForm?.name,
        parentFormId,
        questions,
        rootFormId,
        rootForm:rootForm?.name,
    }
    return modifiedForm
}
    
export default normalizeData;