import { useCallback } from 'react';

import SubmitButton from 'components/SubmitButton';

import isFunction from 'utilities/isFunction';

import styles from './styles.module.scss';

import Spinner from 'components/Spinner';

const SaveButton = ({
    className = '',
    isDisabled = false,
    onClick,
    saveText = 'Save',
    isLoading = false
}) => {
    const handleClick = useCallback(
        clickEvent => {
            if (isFunction(onClick)) {
                onClick(clickEvent);
            }
        },
        [onClick]
    );

    return (
        <SubmitButton
            className={`${className} ${styles.saveButton}`}
            isDisabled={isDisabled}
            onClick={handleClick}
            text={isLoading ? '' : saveText}
        >
            {isLoading ? <Spinner type="save" /> : saveText}
        </SubmitButton>
    );
};

export default SaveButton;
