import { useRecoilCallback, useRecoilValue } from 'recoil';
import { gql } from '@apollo/client';

import state from 'app/state';

import useGraphQL from 'hooks/useGraphQL';

const { addPerson } = state;

const { addPersonAtom } = addPerson.atoms;

const  MUTATION_CREATE_USER = tenantSchema => gql`
    mutation createUser(
        $email: String!,
        $username: String!,
        $employmentDate: timestamp!,
        $firstName: String!,
        $lastName: String!,
    ){
        insert_tenant_user_one(
            object: {
                consultancy_id: 1,
                status: "registered",
                username: $username,
                email: $email,
                role_id: 1,
                password: "passwordless",
                login_type: "password"
                user_${tenantSchema}: {
                    data: {
                        first_name: $firstName,
                        last_name: $lastName,
                        department_id: 1,
                        position_id: 4,
                        employee_status: "consultant",
                        supervisor1_id: 447,
                        phone_number: "0000000000",
                        employment_date: $employmentDate,
                        timezone: "EST",
                        currency: "USD",
                        registration_status: "registered",
                    }
                }
            }
        ){
            id,
        }
    }
`;

const MUTATION_CREATE_USER_ROLE = () => gql`
    mutation createUserRole(
        $userId: Int!,
        $roleId: Int!
    ){
        insert_user_role(
            objects: {
                user_id: $userId,
                role_id: $roleId
            }
        ){
            returning {  
                id
            }
        }
    }
`;

const createCurrenttimestamp = () => {
    return new Date().toISOString();
}

const useData = (providedSchema) => {
    const mutation = useGraphQL(MUTATION_CREATE_USER, providedSchema);
    const mutationRole = useGraphQL(MUTATION_CREATE_USER_ROLE, providedSchema);


    const addPerson = useRecoilCallback(({set} ) => variables => {
        const runMutation = async () => {
            const user = await mutation({
                    email: variables.emailAddress,
                    employmentDate: createCurrenttimestamp(),
                    firstName: variables.firstName,
                    lastName: variables.lastName,
                    username: variables.userName,
            });

            await mutationRole({
                roleId: Number(variables.role),
                userId: Number(user.data.insert_tenant_user_one.id),
            });

            if(variables.hasAllRole){
                await mutationRole({
                    roleId: 6,
                    userId: Number(user.data.insert_tenant_user_one.id),
                })
            }

            set(addPersonAtom, {
                email: variables.email,
                firstName: variables.firstName,
                lastName: variables.lastName,
                username: variables.username
            });
        };

        runMutation();
    });

    return [useRecoilValue(addPersonAtom), addPerson];
};

export default useData;
