import { useEffect, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './styles.module.scss';
import Button from 'components/Button';
import CloseIcon from 'components/icons/CloseIcon';

function getColumnHeader(_index) {
    return `Column Title`;
}

function cellIdentifier(rowIndex, colIndex) {
    return `cell-${rowIndex}-${colIndex}-${uuid()}`;
}

function columnIdentifier(colIndex) {
    return `col-${colIndex}-${uuid()}`;
}

function initTableData({ colsCount, rowsCount }) {
    return Array.from({ length: rowsCount }, (_, rowIndex) =>
        Array.from({ length: colsCount }, (_, colIndex) => {
            return {
                id: cellIdentifier(rowIndex, colIndex),
                value: ''
            };
        })
    );
}

function initColumnHeaders(colsCount) {
    return Array.from({ length: colsCount }, (_, index) => {
        const id = index + 1;
        return {
            id: columnIdentifier(index),
            value: getColumnHeader(id)
        };
    });
}

const CloseButton = ({ onClick }) => (
    <div className={styles.tableRemoveButton} onClick={onClick}>
        <CloseIcon />
    </div>
);

const Table = ({
    colsCount = 3,
    columns,
    data,
    editColumns = false,
    editRows = false,
    rowsCount = 3,
    onChange,
    onRef = (_ref = {}) => ({})
}) => {
    const [tableData, setTableData] = useState(
        data ?? initTableData({ colsCount, rowsCount })
    );
    const [tableColumns, setTableColumns] = useState(
        columns ?? initColumnHeaders(colsCount)
    );

    useEffect(() => {
        if (onChange) {
            onChange(toExport());
        }
    }, [tableColumns, tableData]);

    useEffect(() => {
        if (onRef) {
            onRef({
                appendColumn,
                appendRow,
                hasChanges,
                removeColumn,
                removeRow,
                toExport
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    function appendColumn() {
        const newCol = {
            id: columnIdentifier(tableColumns.length + 1),
            value: getColumnHeader(tableColumns.length + 1)
        };

        const newColumns = [...tableColumns, newCol];

        setTableColumns(newColumns);
        setTableData(
            tableData.map((row, rowIndex) => [
                ...row,
                { id: cellIdentifier(rowIndex, newColumns.length), value: '' }
            ])
        );
    }

    function removeColumn(columnIndex) {
        if (tableColumns.length === 1) {
            return;
        }
        setTableColumns(
            tableColumns.filter((_column, index) => index !== columnIndex)
        );
        setTableData(
            tableData.map(row =>
                row.filter((_cell, index) => index !== columnIndex)
            )
        );
    }

    function removeRow(rowIndex) {
        if (tableData.length === 1) {
            return;
        }
        setTableData(tableData.filter((_row, index) => index !== rowIndex));
    }

    function appendRow() {
        const newRow = Array.from(
            { length: tableColumns.length },
            (_, colIndex) => {
                return {
                    id: cellIdentifier(tableData.length, colIndex),
                    value: ''
                };
            }
        );

        setTableData([...tableData, newRow]);
    }

    function updateData({ colIndex, rowIndex }) {
        return value => {
            setTableData(
                tableData.map((row, rIndex) =>
                    rIndex === rowIndex
                        ? row.map((cell, cIndex) =>
                              cIndex === colIndex ? { ...cell, value } : cell
                          )
                        : row
                )
            );
        };
    }

    function updateHeader({ colIndex }) {
        return value => {
            setTableColumns(
                tableColumns.map((column, index) =>
                    index === colIndex ? { ...column, value } : column
                )
            );
        };
    }

    function toExport() {
        return {
            columns: tableColumns,
            data: tableData
        };
    }

    function hasChanges() {
        return (
            JSON.stringify(columns) !== JSON.stringify(tableColumns) ||
            JSON.stringify(data) !== JSON.stringify(tableData)
        );
    }

    return (
        <table
            className={[
                styles.table,
                editColumns && styles.tableTopPadding,
                editRows && styles.tablePaddingLeft
            ].join(' ')}
        >
            <thead>
                <tr>
                    {tableColumns.map(({ id, value }, colIndex) => (
                        <th className={styles.tableTh} key={id}>
                            {editColumns && (
                                <div
                                    className={
                                        styles.tableColumnButtonContainer
                                    }
                                >
                                    <CloseButton
                                        onClick={() => removeColumn(colIndex)}
                                    />
                                </div>
                            )}

                            <input
                                className={styles.tableInput}
                                onChange={e =>
                                    updateHeader({ colIndex })(e.target.value)
                                }
                                readOnly={!editColumns}
                                value={value}
                            />
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {tableData.map((row, rowIndex) => (
                    <tr className={styles.tableTh}>
                        {row.map((cell, colIndex) => {
                            const isFirstCell = colIndex === 0;
                            const isLastRow = rowIndex === tableData.length - 1;
                            const showRemoveRowButton =
                                editRows && isLastRow && isFirstCell;
                            return (
                                <td
                                    className={styles.tableTd}
                                    key={cell.id}
                                    style={{
                                        position: 'relative'
                                    }}
                                >
                                    {showRemoveRowButton && (
                                        <div
                                            className={
                                                styles.tableFirstCellButtonContainer
                                            }
                                        >
                                            <CloseButton
                                                onClick={() =>
                                                    removeRow(rowIndex)
                                                }
                                            />
                                        </div>
                                    )}

                                    <input
                                        className={styles.tableInput}
                                        value={cell.value}
                                        onChange={e =>
                                            updateData({ colIndex, rowIndex })(
                                                e.target.value
                                            )
                                        }
                                        readOnly={!editRows}
                                    />
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export const FormTable = ({
    columns,
    data,
    editColumns = false,
    editRows = false,
    onChange,
    onFormComponentRef = _ref => ({})
}) => {
    const tableRef = useRef();

    useEffect(() => {
        if (onFormComponentRef) {
            onFormComponentRef({
                hasChanges,
                toExport
            });
        }
    });

    function onTableRef(ref) {
        tableRef.current = ref;
    }

    function addRow() {
        tableRef.current?.appendRow();
    }

    function addColumn() {
        tableRef.current?.appendColumn();
    }

    function toExport() {
        return tableRef.current?.toExport();
    }

    function hasChanges() {
        return tableRef.current?.hasChanges();
    }

    return (
        <div>
            <div className={styles.tableWrapper}>
                <Table
                    columns={columns}
                    data={data}
                    editColumns={editColumns}
                    editRows={editRows}
                    onChange={onChange}
                    onRef={onTableRef}
                />
            </div>

            <div className={styles.tableActionsBar}>
                {editRows && (
                    <Button
                        className={styles.tableAcctionButton}
                        onClick={addRow}
                    >
                        + Add Row
                    </Button>
                )}

                {editColumns && (
                    <Button
                        className={styles.tableAcctionButton}
                        onClick={addColumn}
                    >
                        + Add Column
                    </Button>
                )}
            </div>
        </div>
    );
};
