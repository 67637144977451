export const conditionalSpreadArray = ({ condition, value }) =>
    condition ? [value] : [];

export function snakeCaseToCamelCase(value = '') {
    return value
        .toLowerCase()
        .split('_')
        .map((word, index) =>
            index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        )
        .join('');
}

export const toDateString = date => {
    return date ? new Date(date).toISOString().split('T')[0] : '';
};

export const classNames = (...args) => {
    return args.filter(Boolean).join(' ');
};

export const safeJsonParse = (value, defaultValue) => {
    try {
        return JSON.parse(value);
    } catch (e) {
        return defaultValue;
    }
};

export const isObject = value => {
    return typeof value === 'object' && value !== null;
};

export const parseJsonObject = value => {
    const jsonObject = safeJsonParse(value, value);
    return isObject(jsonObject) ? jsonObject : value;
};
