import React from 'react';
import { useCallback, useEffect, useState } from 'react';

import FileInput from 'components/FileInput';
import FileUploadPreview from 'components/FileUploadPreview';
import UploadIcon from 'components/icons/UploadIcon';

import styles from './styles.module.scss';

import useTranslation from './hooks/useTranslation';
import Input from 'components/Input';

import handleSubmitOnEnter from 'utilities/handleSubmitOnEnter';

import { getStateFromUrls } from './helpers';

const FileUpload = props => {
    const { ADDITIONAL, TITLE, DESCRIPTION } = useTranslation();

    const {
        accept,
        autoSubmitUrl = false,
        className = '',
        disabled = false,
        filesList,
        maxFiles,
        maxSize,
        persist = true,
        providedSchema,
        showUrl=true,
        title = TITLE,
        uploadFile,
        value = []
    } = props;

    const [files, setFiles] = useState(() => getStateFromUrls(value));

    const handleSubmit = useCallback(
        event => {
            const url = event.target.value?.trim() ?? '';
            if (url.length === 0) return;

            setFiles(prevState => {
                const newState = {};
                for (const key in prevState) {
                    if (autoSubmitUrl && prevState[key].url) {
                        continue;
                    }
                    newState[key] = prevState[key];
                }

                newState[url] = {
                    ...prevState[url],
                    isUploadError: false,
                    isUploading: false,
                    url
                };

                return newState;
            });
        },[setFiles]
    );

    const onKeyUp = autoSubmitUrl ? handleSubmit : handleSubmitOnEnter(handleSubmit);

    useEffect(()=>{
        filesList(files);
    },[files, filesList])

    return (
        <>
            <FileInput
                accept={accept}
                className={`${className} ${styles.fileUpload}`}
                disabled={disabled}
                filesList={filesList}
                maxFiles={maxFiles}
                maxSize={maxSize}
                persist={persist}
                providedSchema={providedSchema}
                renderPreview={({ files, removeFileHandler }) => (
                    <FileUploadPreview files={files} onRemove={removeFileHandler} />
                )}
                uploadFile={uploadFile}
                value={value}
            >
                <div className={styles.target}>
                    <UploadIcon className={styles.logo} />

                    <div>
                        <h5 className={styles.title}>{title}</h5>

                        <p className={styles.description}>{DESCRIPTION}</p>

                        <br />

                        <p className={styles.description}>{ADDITIONAL}</p>
                    </div>
                </div>
            </FileInput>

            {showUrl &&
                <Input
                    className={styles.input}
                    id={'hyperlinkBox'}
                    onKeyUp={onKeyUp}
                    placeholder={"https://"}
                    type="text"
                    // value={"https://"}
                />
            }
        </>
    );
};

export default FileUpload;
