import { useCallback, useEffect, useState } from 'react';

import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import SaveButton from 'components/SaveButton';

import isFunction from 'utilities/isFunction';

const CancelSaveButtons = ({
    className = '',
    isSaveDisabled,
    onCancel,
    onSave,
    saveText
}) => {
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
        useState(isSaveDisabled ?? false);

    const handleCancel = useCallback(
        clickEvent => {
            if (isFunction(onCancel)) {
                onCancel(clickEvent);
            }
        },
        [onCancel]
    );

    const handleSave = useCallback(
        clickEvent => {
            setIsSaveButtonDisabled(true);

            if (isFunction(onSave)) {
                setIsSaveButtonDisabled(false);
                onSave(clickEvent);
            }
        },
        [onSave]
    );

    useEffect(() => {
        setIsSaveButtonDisabled(isSaveDisabled);
    }, [isSaveDisabled]);

    return (
        <Buttons className={className}>
            <CancelButton onClick={handleCancel} />

            <SaveButton
                isDisabled={isSaveButtonDisabled}
                onClick={handleSave}
                saveText={saveText}
            />
        </Buttons>
    );
};

export default CancelSaveButtons;
