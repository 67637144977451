function setTabTitle() {
  if(window.location.hostname.includes('regtechsolutions')){
    document.title = 'Beacon';
  }else if (window.location.hostname.includes('skematic')){
    document.title = 'Skematic';
  } else if (window.location.hostname.includes('localhost')){
    document.title = 'Localhost - Skematic';
  } else {
    document.title = '';
  }
}

export default setTabTitle