import { useCallback, useEffect, useState, useMemo } from 'react';

import ActivityUserList from 'components/ActivityUserList';
import AddAssignee from 'components/AddAssignee';
import AddReviewer from 'components/AddReviewer';
import ContentPanel from 'components/ContentPanel';
import EditableDatePickerWithLabel from 'components/EditableDatePickerWithLabel';
import EditableMultiSelectWithLabel from 'components/EditableMultiSelectWithLabel';
import EntityTags from 'components/EntityTags';
import ImportantLinkInNewTab from 'components/ImportantLinkInNewTab';
import PanelBody from 'components/PanelBody';
import SectionWithBorder from 'components/SectionWithBorder';
import SelectUsersDrawer from 'components/SelectUsersDrawer';
import Title from 'components/Title';
import UpdateRecurrenceEndDateModal from 'components/UpdateRecurrenceEndDateModal';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useTranslation from './hooks/useTranslation';
import useStartDate from './hooks/useStartDate';
import { useUpdateActivityDueDate } from './hooks/useEndDate';
import useHierarchy from './hooks/useHierarchy';
import useSendEmail from './hooks/useSendEmail';
import useActivityUser from './hooks/useActivityUser';
import useUpdateEntities from './hooks/useUpdateEntities';

import styles from './styles.module.scss';

import DateWithLabel from 'components/DateWithLabel';
import CheckPermissionsAlternateElement from 'components/CheckPermissionsAlternateElement';
import EditRecurrenceButton from 'components/EditRecurrenceButton';
import TextWithLabel from 'components/TextWithLabel';
import titleCase from 'utilities/titleCase';
import WithLabel from 'components/WithLabel';
import ToggleWithLabel from 'components/ToggleWithLabel';
import CollapsiblePanelWithChildren from 'components/CollapsiblePanelWithChildren';
import CheckPermissions from 'components/CheckPermissions';
import updateActivities from 'utilities/updateActivities';

const ActivityMetaPanel = ({
    activityForms,
    activityEntityData: activityEntities,
    activityLinkages,
    activityName,
    activityPolicy,
    activityRecurrence,
    activitySchema,
    // activityUsersForRecurrence,
    addAssignee,
    addReviewerGroup,
    allEntityData: entities,
    allPreviouslyAssignedUsers,
    caseLinkages,
    className = '',
    closed,
    dueDate: prevActivityDueDate,
    hierarchical,
    id,
    instructions,
    logLinkages,
    recurrenceType,
    recurrences,
    refetchActivity,
    sendEmail,
    startDate,
    type,
    // entities,
    users
}) => {
    const futureRecurrences = (recurrences ?? []).filter(recurrence => {
        return new Date(recurrence.dueDate) > new Date(prevActivityDueDate);
    });

    const { COMPANY, DUE_DATE, FREQUENCY, HIERARCHY, SEND_EMAIL, START_DATE } =
        useTranslation();
    const { ACTIVITY_ROUTE, CASE_ROUTE, LOG_ROUTE } = useConfig();

    const { useActivities, useConsultancyUsers, useTenants, useUserId } =
        useGlobalStateHooks();
    const [activities, setActivities] = useActivities();
    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [consultancyUsers] = useConsultancyUsers();

    const [savedStartDate, saveStartDate, resetStartDate] =
        useStartDate(activitySchema);
    const updateActivityDueDate = useUpdateActivityDueDate(activitySchema);
    const [savedHierarchy, saveHierarchy, resetHierarchy] =
        useHierarchy(activitySchema);
    const [savedSendEmail, saveSendEmail, resetSendEmail] =
        useSendEmail(activitySchema);
    const [savedActivityUser, saveActivityUser, resetActivityUser] =
        useActivityUser(activitySchema);
    const [updatedEntities, updateEntities, resetUpdateEntities] =
        useUpdateEntities(activitySchema);

    const [_errorMessage, setErrorMessage] = useState();
    const [displayedStartDate, setStartDate] = useState(startDate);
    const [displayedHierarchy, setHierarchy] = useState();
    const [displayedSendEmail, setSendEmail] = useState();
    const [currentActivityDueDate, setActivityDueDate] =
        useState(prevActivityDueDate);
    const [endDateModalOpen, setEndDateModalOpen] = useState(false);
    const [isEditingEntities, setIsEditingEntities] = useState(false);
    const [selectedEntityLabels, setSelectedEntityLabels] = useState();
    const [selectedEntityValues, setSelectedEntityValues] = useState();
    const [actingUserId, setActingUserId] = useState(userId);

    const handleStartDateSave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId: id
                }
            );
            formObject.text = new Date(formObject.text);

            saveStartDate(formObject);
        },
        [id, saveStartDate]
    );

    const linkages = (activityLinkages ?? [])
        .map(linkage => ({
            ...linkage,
            sort_date: new Date(linkage?.name.split(' - Due ')[1])
        }))
        .sort((a, b) => a.sort_date - b.sort_date);

    useEffect(() => {
        if (!savedStartDate) {
            return;
        }
        setStartDate(savedStartDate);
        updateActivities(
            activities,
            setActivities,
            id,
            'startDate',
            savedStartDate
        );
        resetStartDate();
        // eslint-disable-next-line
    }, [resetStartDate, savedStartDate, setActivities, setStartDate]);

    const handleEndDateSave = ({ form }) => {
        const { handleForm } = form;
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);
            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;

                return accumulator;
            },
            {
                activityId: id
            }
        );

        if (formObject.text === '') {
            return;
        }
        const newActivtyDueDate = new Date(formObject.text);
        formObject.text = newActivtyDueDate;
        setActivityDueDate(newActivtyDueDate);
        setEndDateModalOpen(true);
        updateActivityDueDate(formObject);
    };

    const handleHierarchySave = useCallback(() => {
        const formObject = {
            activityId: id,
            hierarchical: !displayedHierarchy
        };
        saveHierarchy(formObject);
    }, [displayedHierarchy, id, saveHierarchy]);

    useEffect(() => {
        if (!savedHierarchy) {
            return;
        }
        updateActivities(
            activities,
            setActivities,
            id,
            'isHierarchical',
            savedHierarchy
        );
        setHierarchy(savedHierarchy);
        resetHierarchy();
        // eslint-disable-next-line
    }, [resetHierarchy, savedHierarchy, setHierarchy]);

    const handleSendEmailSave = useCallback(() => {
        const formObject = { activityId: id, sendEmail: !displayedSendEmail };
        saveSendEmail(formObject);
    }, [displayedSendEmail, id, saveSendEmail]);

    useEffect(() => {
        if (!savedSendEmail) {
            return;
        }
        setSendEmail(savedSendEmail);
        resetSendEmail();
    }, [resetSendEmail, savedSendEmail, setSendEmail]);

    const saveActivityUsers = useCallback(
        ({ assignmentType, isSelected, previousRecord, type, userId }) => {
            const formObject = {
                actingUserId: actingUserId,
                activityId: id,
                activityName,
                assignmentType,
                dueDate: currentActivityDueDate,
                groupId: type === 'group' ? userId : null,
                previousRecord,
                status: isSelected ? 'removed' : 'inprogress',
                userId: type === 'user' ? userId : null,
                userName: consultancyUsers.filter(obj => {
                    return obj.id === userId;
                })[0]?.name
            };

            saveActivityUser(formObject);
        },
        [
            activityName,
            actingUserId,
            currentActivityDueDate,
            id,
            saveActivityUser,
            consultancyUsers
        ]
    );

    useEffect(() => {
        if (!savedActivityUser) {
            return;
        }
        setActivities({});
        window.location.reload();
    }, [resetActivityUser, savedActivityUser, setActivities]);

    const memoizedSelectedValues = useMemo(() => {
        if (!entities) {
            return [];
        }

        return activityEntities.map(entity => entity.value);
    }, [activityEntities, entities]);

    const memoizedSelectedLabels = useMemo(() => {
        if (!entities) {
            return [];
        }

        return entities
            .filter(entity => memoizedSelectedValues.includes(entity.value))
            .map(entity => entity.label);
    }, [entities, memoizedSelectedValues]);

    useEffect(() => {
        if (
            JSON.stringify(selectedEntityValues) !==
            JSON.stringify(memoizedSelectedValues)
        ) {
            setSelectedEntityValues(memoizedSelectedValues);
        }

        if (
            JSON.stringify(selectedEntityLabels) !==
            JSON.stringify(memoizedSelectedLabels)
        ) {
            setSelectedEntityLabels(memoizedSelectedLabels);
        }
    }, [
        memoizedSelectedValues,
        memoizedSelectedLabels,
        selectedEntityValues,
        selectedEntityLabels
    ]);

    const handleEntities = useCallback(() => {
        setIsEditingEntities(true);
    }, []);

    const handleEntitySave = useCallback(
        ({ form }) => {
            const { handleForm } = form;

            const output = handleForm();

            if (typeof output === 'string') {
                setErrorMessage(output);

                return;
            }

            const formObject = Array.from(output.entries()).reduce(
                (accumulator, [key, value]) => {
                    accumulator[key] = value;

                    return accumulator;
                },
                {
                    activityId: id,
                    userId: userId
                }
            );
            formObject['entities'] = JSON.parse(formObject.selected);

            updateEntities(formObject);
        },
        [id, updateEntities, userId]
    );

    useEffect(() => {
        if (!updatedEntities) {
            return;
        }

        setIsEditingEntities(false);
        setSelectedEntityValues(updatedEntities);

        const selectedEntityValuesArray = [];
        for (const updatedEntityValue of updatedEntities) {
            selectedEntityValuesArray.push(
                entities.find(entity => entity.value === updatedEntityValue)
            );
        }

        const selectedLabels = selectedEntityValuesArray.map(
            ({ label }) => label
        );

        setSelectedEntityLabels(selectedLabels);
        resetUpdateEntities();
    }, [
        entities,
        isEditingEntities,
        resetUpdateEntities,
        updatedEntities,
        setSelectedEntityValues
    ]);

    useEffect(() => {
        setActingUserId(userId);
    }, [userId]);

    // eslint-disable-next-line
    useEffect(() => {
        if (!displayedStartDate) {
            setStartDate(startDate);
        }
        if (!displayedHierarchy) {
            setHierarchy(hierarchical);
        }
        if (!displayedSendEmail) {
            setSendEmail(sendEmail);
        }
    });

    const handleEndDateModalClose = () => {
        setEndDateModalOpen(false);
    };

    function onSaveFutureDate() {
        refetchActivity();
    }

    const showChangeEndDateModal =
        endDateModalOpen && currentActivityDueDate && recurrences?.length > 0;

    return (
        <ContentPanel className={`${className} ${styles.activityMetaPanel}`}>
            <PanelBody className={styles.panelBody}>
                <CheckPermissionsAlternateElement
                    permission={['activity_update']}
                >
                    <EditableDatePickerWithLabel
                        date={displayedStartDate}
                        hasEdit={!closed}
                        onSave={handleStartDateSave}
                        text={START_DATE}
                    />

                    <DateWithLabel
                        date={displayedStartDate}
                        text={START_DATE}
                    />
                </CheckPermissionsAlternateElement>

                <CheckPermissionsAlternateElement
                    permission={['activity_update']}
                >
                    <EditableDatePickerWithLabel
                        date={currentActivityDueDate}
                        hasEdit={!closed}
                        onSave={handleEndDateSave}
                        text={DUE_DATE}
                    />

                    <DateWithLabel
                        date={currentActivityDueDate}
                        text={DUE_DATE}
                    />
                </CheckPermissionsAlternateElement>

                <CheckPermissionsAlternateElement
                    permission={['activity_update']}
                >
                    <div className={styles.frequency}>
                        <TextWithLabel
                            className={styles.frequencyText}
                            label={FREQUENCY}
                            text={
                                recurrenceType ? titleCase(recurrenceType) : '-'
                            }
                        />

                        <EditRecurrenceButton
                            activityEntities={selectedEntityValues}
                            activityForms={activityForms}
                            activityId={id}
                            activityInstructions={instructions}
                            activityName={activityName}
                            activityPolicy={activityPolicy}
                            activityRecurrence={activityRecurrence}
                            activitySchema={activitySchema}
                            activityType={type}
                            activityUsers={
                                consultancyUsers
                                    ? consultancyUsers.filter(item =>
                                          item.tenants.includes(activitySchema)
                                      )
                                    : []
                            }
                            className={styles.editRecurrenceButton}
                            currentActivityEndDate={currentActivityDueDate}
                            currentActivityStartDate={displayedStartDate}
                            hasEdit={!closed}
                            hierarchical={hierarchical}
                            recurrences={futureRecurrences}
                            onSuccess={refetchActivity}
                        />
                    </div>

                    <TextWithLabel
                        className={styles.frequencyText}
                        label={FREQUENCY}
                        text={recurrenceType ? titleCase(recurrenceType) : '-'}
                    />
                </CheckPermissionsAlternateElement>

                {Object.keys(users).map(key => (
                    <CheckPermissionsAlternateElement
                        permission={['activity_update']}
                    >
                        <SelectUsersDrawer
                            allPreviouslyAssignedUsers={
                                allPreviouslyAssignedUsers &&
                                allPreviouslyAssignedUsers[key]?.users
                                    ? allPreviouslyAssignedUsers[key]?.users
                                    : []
                            }
                            assignmentType={key}
                            onChange={saveActivityUsers}
                            selectedUsers={users ? users[key].users : []}
                            users={
                                consultancyUsers
                                    ? consultancyUsers.filter(item =>
                                          item.tenants.includes(activitySchema)
                                      )
                                    : []
                            }
                        >
                            {({ toggle }) => (
                                <ActivityUserList
                                    className={styles.activityUserList}
                                    isCompleted={
                                        users[key].status === 'complete'
                                    }
                                    key={key}
                                    onEditClick={closed ? null : toggle}
                                    title={key
                                        .replace('_', ' ')
                                        .replace('-', ' ')
                                        .toLowerCase()
                                        .split(' ')
                                        .map(val =>
                                            val.length > 0
                                                ? val.replace(
                                                      val[0],
                                                      val[0].toUpperCase()
                                                  )
                                                : ''
                                        )
                                        .join(' ')}
                                    users={users[key].users}
                                />
                            )}
                        </SelectUsersDrawer>

                        <ActivityUserList
                            className={styles.activityUserList}
                            isCompleted={users[key].status === 'complete'}
                            key={key}
                            title={key
                                .replace('_', ' ')
                                .replace('-', ' ')
                                .toLowerCase()
                                .split(' ')
                                .map(val =>
                                    val.length > 0
                                        ? val.replace(
                                              val[0],
                                              val[0].toUpperCase()
                                          )
                                        : ''
                                )
                                .join(' ')}
                            users={users[key].users}
                        />
                    </CheckPermissionsAlternateElement>
                ))}

                {!closed && <AddAssignee onClick={addAssignee} />}

                {!Object.keys(users).some(group => group === 'reviewer') &&
                    !closed && <AddReviewer onClick={addReviewerGroup} />}

                <CheckPermissionsAlternateElement
                    permission={['activity_update']}
                >
                    <>
                        {!isEditingEntities && (
                            <EntityTags
                                className={styles.entityTags}
                                items={memoizedSelectedLabels ?? []}
                                onEditClick={closed ? null : handleEntities}
                            />
                        )}

                        {isEditingEntities && (
                            <EditableMultiSelectWithLabel
                                className={styles.editingEntities}
                                editOn={true}
                                hasEdit={!closed}
                                items={entities}
                                label={'Entity Tag'}
                                onClose={() => setIsEditingEntities(false)}
                                onSave={handleEntitySave}
                                selected={selectedEntityValues}
                            />
                        )}
                    </>

                    <EntityTags
                        className={styles.entityTags}
                        items={selectedEntityLabels ?? []}
                    />
                </CheckPermissionsAlternateElement>

                {linkages.length > 0 && (
                    <SectionWithBorder
                        className={styles.sectionWithBorder}
                        limitHeight={true}
                    >
                        <Title
                            className={styles.withLabel}
                            text={`Linked Activities`}
                        />

                        <div className={styles.activityLinkages}>
                            {linkages.map(activityLink => {
                                const colorStatus =
                                    activityLink.status === 'completed'
                                        ? 'completed'
                                        : activityLink.status ===
                                              'inprogress' ||
                                          activityLink.status === 'overdue'
                                        ? 'current'
                                        : 'future';
                                return (
                                    <ImportantLinkInNewTab
                                        className={`${styles.scrollOverflow} ${
                                            styles[`${colorStatus}`]
                                        }`}
                                        openNewTab={activityLink}
                                        text={`${activityLink?.name}`}
                                        to={ACTIVITY_ROUTE.replace(
                                            ':activityId',
                                            activityLink?.id
                                        ).replace(
                                            ':activitySchema',
                                            activitySchema
                                        )}
                                    />
                                );
                            })}
                        </div>
                    </SectionWithBorder>
                )}

                {caseLinkages.length > 0 && (
                    <SectionWithBorder className={styles.sectionWithBorder}>
                        <Title
                            className={styles.withLabel}
                            text={`Linked Cases`}
                        />

                        {caseLinkages.map(caseLink => (
                            <ImportantLinkInNewTab
                                className={styles.importantLinkInNewTab}
                                openNewTab={caseLink}
                                text={`${caseLink?.name}`}
                                to={CASE_ROUTE.replace(
                                    ':caseId',
                                    caseLink?.id
                                ).replace(':caseSchema', activitySchema)}
                            />
                        ))}
                    </SectionWithBorder>
                )}

                {logLinkages.length > 0 && (
                    <SectionWithBorder className={styles.sectionWithBorder}>
                        <Title
                            className={styles.withLabel}
                            text={`Linked Logs`}
                        />

                        {logLinkages.map(logLink => {
                            return (
                                <ImportantLinkInNewTab
                                    className={styles.importantLinkInNewTab}
                                    openNewTab={logLink}
                                    text={`${logLink?.name}`}
                                    to={LOG_ROUTE.replace(
                                        ':logId',
                                        logLink?.id
                                    ).replace(':logSchema', activitySchema)}
                                />
                            );
                        })}
                    </SectionWithBorder>
                )}

                {tenants.length > 1 && (
                    <TextWithLabel
                        className={styles.frequencyText}
                        label={COMPANY}
                        text={activitySchema ? titleCase(activitySchema) : '-'}
                    />
                )}

                {!closed && (
                    <CheckPermissions permission={['activity_update']}>
                        <CollapsiblePanelWithChildren
                            className={styles.advancedSettings}
                            id={'advanced_settings'}
                            label={'Advanced Settings'}
                        >
                            <WithLabel text={HIERARCHY}>
                                <ToggleWithLabel
                                    isActive={!displayedHierarchy}
                                    onToggle={handleHierarchySave}
                                />
                            </WithLabel>

                            <WithLabel text={SEND_EMAIL}>
                                <ToggleWithLabel
                                    isActive={!displayedSendEmail}
                                    onToggle={handleSendEmailSave}
                                />
                            </WithLabel>
                        </CollapsiblePanelWithChildren>
                    </CheckPermissions>
                )}

                {showChangeEndDateModal && (
                    <UpdateRecurrenceEndDateModal
                        activitySchema={activitySchema}
                        daysBeforeDueDate={activityRecurrence.startDate}
                        dueDate={currentActivityDueDate}
                        onClose={handleEndDateModalClose}
                        onSave={onSaveFutureDate}
                        recurrenceType={recurrenceType}
                        recurrences={futureRecurrences}
                    />
                )}
            </PanelBody>
        </ContentPanel>
    );
};

export default ActivityMetaPanel;
