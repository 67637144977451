import { useNavigate } from 'react-router-dom';

import ActiveIndicator from 'components/ActiveIndicator';
import BodyText from 'components/BodyText';
import DetailsButton from 'components/DetailsButton';
import ImportantLink from 'components/ImportantLink';
import PenIcon from 'components/icons/PenIcon';
import TableRow from 'components/TableRow';

import useTranslation from './hooks/useTranslation';

import useConfig from 'hooks/useConfig';

import titleCase from 'utilities/titleCase';
import styles from './styles.module.scss';
import { Badges } from 'components/badges/badges';

const DocumentManagementTableRow = props => {
    const { effectiveDate, id, name, schema, selectedCategories, status, tenantCount = 1, version, visibleTo } = props;

    const { POLICY_ROUTE } = useConfig();

    const { EDIT } = useTranslation();

    const navigate = useNavigate();

    const isActive = status.toLowerCase() === 'active';

    if (tenantCount > 1) {
        return (
            <TableRow className={styles.documentManagementTableRowMultiTenant}>
                <ImportantLink
                    className={styles.link}
                    text={name}
                    to={`${POLICY_ROUTE.replace(':policyId', id).replace(':policySchema', schema)}`}
                />

                <BodyText className={styles.version} text={version || '-'} />

                <BodyText text={effectiveDate} />

                <BodyText text={schema ? titleCase(schema) : '-'} />

                <Badges items={visibleTo} />

                <Badges items={selectedCategories} /> 

                <ActiveIndicator
                    className={styles.statusIcon}
                    isActive={isActive}
                />

                <DetailsButton
                    className={styles.detailsButton}
                    items={[
                        {
                            icon: <PenIcon />,

                            onClick: () => {
                                navigate('#');
                            },

                            text: EDIT
                        }
                    ]}
                />
            </TableRow>
        );
    } else {
        return (
            <TableRow className={styles.documentManagementTableRow}>
                <ImportantLink
                    className={styles.link}
                    text={name}
                    to={`${POLICY_ROUTE.replace(':policyId', id).replace(':policySchema', schema)}`}
                />

                <BodyText className={styles.version} text={version || '-'} />

                <BodyText text={effectiveDate} />

                <Badges items={visibleTo} />

                <Badges items={selectedCategories} />   

                <ActiveIndicator
                    className={styles.statusIcon}
                    isActive={isActive}
                />

                <DetailsButton
                    className={styles.detailsButton}
                    items={[
                        {
                            icon: <PenIcon />,

                            onClick: () => {
                                navigate('#');
                            },

                            text: EDIT
                        }
                    ]}
                />
            </TableRow>
        );
    }
};

export default DocumentManagementTableRow;
