import { useMutation, gql } from '@apollo/client';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

const mutationLogUserAccess = () => gql`
  mutation LogUserAccess($selectedConsultancyIds: jsonb!, $tenantUserId: Int!, $url: String!) {
    insert_access_logs(
      objects: {
        selected_consultancy_ids: $selectedConsultancyIds,
        tenant_user_id: $tenantUserId,
        url: $url
      }
    ) {
      returning {
        id
        created_at
      }
    }
  }
`;

const useLogUserAccess = () => {
    const { useUserId, useTenants, useSchema } = useGlobalStateHooks();


    const [userId] = useUserId();
    const [tenants] = useTenants();
    const [schema] = useSchema();

    const [logUserAccessMutation, { loading, error, data }] = useMutation(mutationLogUserAccess(), {
        fetchPolicy: 'no-cache',
    });

    const logUserAccess = (url, consultancyUsers) => {
        const isConsultancyUser = (userId) => {
            return consultancyUsers.some((user) => user.tenant_user_id === Number(userId));
        };
    
        const consultancyIds = isConsultancyUser(userId) ? tenants : [schema];
        logUserAccessMutation({
        variables: {
            selectedConsultancyIds: consultancyIds,
            tenantUserId: userId,
            url
        },
        });
    };

    return { logUserAccess, loading, error, data };
};

export default useLogUserAccess;
