import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useLogUserAccess from 'hooks/useLogUserAccess.hook';

const RouteInterceptor = ({ children, consultancyUsers }) => {
  const location = useLocation();
  const { logUserAccess } = useLogUserAccess();

  useEffect(() => {
    const fullUrl = `${location.pathname}${location.search}`;
    if(consultancyUsers && consultancyUsers.length > 0) {
        logUserAccess(fullUrl, consultancyUsers);
    }
  }, [consultancyUsers, location.pathname, location.search]);

  return children;
};

export default RouteInterceptor;
